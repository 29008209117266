<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="salvar">
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-text-field
          label="Nome"
          ref="nome"
          outlined
          dense
          v-model="form.nome"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-text-field
          label="Atividade"
          ref="atividade"
          outlined
          dense
          v-model="form.atividade"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-text-field
          label="Documento (CPF / CNPJ)"
          ref="documento"
          outlined
          dense
          v-model="form.documento"
          :rules="[rules.required, rules.documento]"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-text-field
          label="Telefone"
          ref="telefone"
          outlined
          dense
          v-model="form.telefone"
          :rules="[rules.required]"
          v-mask="['(##) #####-####']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Logradouro"
          ref="logradouro"
          outlined
          dense
          v-model="form.endereco.logradouro"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" lg="2">
        <v-text-field
          label="Número"
          ref="numero"
          outlined
          dense
          v-model="form.endereco.numero"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" lg="2">
        <v-text-field
          label="Complemento"
          ref="complemento"
          outlined
          dense
          v-model="form.endereco.complemento"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Bairro"
          ref="bairro"
          outlined
          dense
          v-model="form.endereco.bairro"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4" lg="4">
        <v-text-field
          label="Cidade"
          ref="cidade"
          outlined
          dense
          v-model="form.endereco.cidade"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" lg="2">
        <v-text-field
          label="UF"
          ref="uf"
          outlined
          dense
          v-model="form.endereco.uf"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3">
        <v-text-field
          label="CEP"
          ref="cep"
          outlined
          dense
          v-model="form.endereco.cep"
          :rules="[rules.required]"
          v-mask="['#####-###']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3" v-if="pf">
        <v-text-field
          label="Nacionalidade"
          ref="nacionalidade"
          outlined
          dense
          v-model="form.nacionalidade"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" v-if="pf">
        <v-text-field
          label="Estado Civil"
          ref="estadoCivil"
          outlined
          dense
          v-model="form.estadoCivil"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" v-if="pf">
        <v-text-field
          label="Profissao"
          ref="profissao"
          outlined
          dense
          v-model="form.profissao"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          outlined
          name="Observação"
          label="Observação"
          rows="3"
          row-height="15"
          v-model="form.observacao"
        ></v-textarea>
      </v-col>
      <v-col cols="12" align="right">
        <v-btn
          dark
          depressed
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Fornecedor from "@/service/fornecedor";
export default {
  name: "FormFornecedor",
  props: {
    payload: {
      type: Object,
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      form: {
        nome: "",
        documento: "",
        telefone: "",
        observacao: "",
        endereco: {
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: "",
          cep: "",
        },
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
        documento: (value) => {
          if (value && value.length != 14 && value.length != 18)
            return (value.length < 14 ? "CPF" : "CNPJ") + " inválido.";

          return true;
        },
      },
    };
  },
  computed: {
    pf() {
      const pattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

      return pattern.test(this.form.documento);
    },
  },
  methods: {
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    async salvar() {
      this.formToUpperCase();

      if (!this.$refs.form.validate()) return;

      try {
        const payload = {
          ...this.form,
          empresa: false,
        };
        await Fornecedor.save(payload);

        this.$emit("sucesso");
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao salvar os dados!",
          type: "error",
        });
        console.log(error);
      }
    },
  },
  created() {
    if (this.payload && this.payload.id) {
      this.form = { ...this.payload };
    }
  },
};
</script>

<style scoped>
div input {
  text-transform: uppercase;
}
</style>
